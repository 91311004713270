import React from "react";
import { Link } from "react-router-dom";
import OurServices from "../Container/OurServices";

const HomePage = () => {
  return (
    <div className="min-h-screen bg-[#00133e] text-white font-sans px-8">
      <OurServices />
      {/* Hero Section */}
      <section
        id="home"
        className="text-center py-20 px-6 bg-cover bg-center"
        style={{
          backgroundImage:
            "url('images/A_heartwarming_image_of_a_family_together,_with_pa.png')",
        }}
      >
        <h1 className="text-5xl font-bold mb-4">Stay Connected, Stay Safe</h1>
        <p className="text-xl mb-8">
          In emergencies, every second counts. Ensure your loved ones are
          informed instantly.
        </p>
        <button className="bg-white text-[#00133e] font-semibold py-2 px-4 rounded">
          Get Your QR Code Now
        </button>
      </section>

      {/* How It Works Section */}
      <section id="how-it-works" className="py-20 px-6">
        <h2 className="text-4xl font-bold text-center mb-12">How It Works</h2>
        <div className="grid md:grid-cols-3 gap-12">
          <div className="text-center">
            <img
              src="images/A_car_with_a_visible_QR_code_on_the_back_window,_p.png"
              alt="Step 1"
              className="mx-auto mb-4"
            />
            <h3 className="text-2xl font-semibold mb-2">Step 1</h3>
            <p>
              Easily attach the QR code to your vehicle in a prominent location.
            </p>
          </div>
          <div className="text-center">
            <img
              src="images/A_person_scanning_a_QR_code_on_a_car_with_their_sm.png"
              alt="Step 2"
              className="mx-auto mb-4"
            />
            <h3 className="text-2xl font-semibold mb-2">Step 2</h3>
            <p>
              In an emergency, a quick scan provides immediate access to crucial
              information.
            </p>
          </div>
          <div className="text-center">
            <img
              src="images/A_family_receiving_a_notification_on_their_phones,.png"
              alt="Step 3"
              className="mx-auto mb-4"
            />
            <h3 className="text-2xl font-semibold mb-2">Step 3</h3>
            <p>
              Your loved ones will be notified instantly, ensuring prompt
              assistance.
            </p>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-20 px-6 bg-[#00133e]">
        <h2 className="text-4xl font-bold text-center mb-12">
          Why Choose Owner Dekhle?
        </h2>
        <div className="grid md:grid-cols-3 gap-12">
          <div className="text-center">
            <img
              src="images/A_smartphone_displaying_notification_icons,_emphas.png"
              alt="Instant Alerts"
              className="mx-auto mb-4"
            />
            <h3 className="text-2xl font-semibold mb-2">Instant Alerts</h3>
            <p>
              Immediate notifications to your family members in case of an
              emergency.
            </p>
          </div>
          <div className="text-center">
            <img
              src="images/A_smartphone_with_a_user-friendly_interface,_showi.png"
              alt="Easy to Use"
              className="mx-auto mb-4"
            />
            <h3 className="text-2xl font-semibold mb-2">Easy to Use</h3>
            <p>Simple setup and use with any smartphone.</p>
          </div>
          <div className="text-center">
            <img
              src="images/A_relaxed_family_enjoying_time_together_at_home,_s.png"
              alt="Peace of Mind"
              className="mx-auto mb-4"
            />
            <h3 className="text-2xl font-semibold mb-2">Peace of Mind</h3>
            <p>Know that your loved ones are just a scan away.</p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section id="testimonials" className="py-20 px-6">
        <h2 className="text-4xl font-bold text-center mb-12">
          What Our Users Say
        </h2>
        <div className="grid md:grid-cols-2 gap-12">
          <div className="text-center">
            <img
              src="images/A_smiling_person_giving_a_thumbs_up,_showing_satis.png"
              alt="Testimonial 1"
              className="mx-auto mb-4 rounded-full"
            />
            <p className="italic">
              "Owner Dekhle gave me peace of mind knowing my family can be
              informed instantly in case of an emergency."
            </p>
            <p className="font-bold mt-2">- Rajesh K.</p>
          </div>
          <div className="text-center">
            <img
              src="images/A_happy_person_showing_a_positive_gesture,_such_as.png"
              alt="Testimonial 2"
              className="mx-auto mb-4 rounded-full"
            />
            <p className="italic">
              "A must-have for every vehicle owner. It’s simple yet incredibly
              effective."
            </p>
            <p className="font-bold mt-2">- Priya M.</p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section id="cta" className="text-center py-20 px-6 bg-[#00133e]">
        <h2 className="text-4xl font-bold mb-4">
          Ready to Protect Your Loved Ones?
        </h2>
        <p className="text-xl mb-8">
          Get started today with Owner Dekhle and ensure your family's safety.
        </p>
        <button className="bg-white text-[#00133e] font-semibold py-2 px-4 rounded">
          Get Started Now
        </button>
      </section>

      {/* Footer */}
      <footer className="bg-[#00133e] text-center py-6">
        <p>© 2024 Owner Dekhle. All rights reserved.</p>
        <div className="flex justify-center space-x-6 mt-4">
          <a href="#" className="hover:underline">
            Facebook
          </a>
          <a href="#" className="hover:underline">
            Twitter
          </a>
          <a href="#" className="hover:underline">
            Instagram
          </a>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
