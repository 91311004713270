import React from "react";

const AboutUsPage = () => {
  return (
    <div className="min-h-screen bg-[#00133e] text-white font-sans">
      {/* Header */}
      <header className="flex flex-wrap justify-between items-center p-6">
        <div className="text-3xl font-bold">Owner Dekhle</div>
        <nav className="w-full mt-4 md:mt-0 md:w-auto">
          <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6">
            <li>
              <a href="/" className="hover:underline">
                Home
              </a>
            </li>
            <li>
              <a href="/download" className="hover:underline">
                Download
              </a>
            </li>
            <li>
              <a href="/about-us" className="hover:underline">
                About Us
              </a>
            </li>
            <li>
              <a href="#how-it-works" className="hover:underline">
                How It Works
              </a>
            </li>
            <li>
              <a href="#testimonials" className="hover:underline">
                Testimonials
              </a>
            </li>
            <li>
              <a href="#contact" className="hover:underline">
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </header>

      {/* About Us Section */}
      <section id="about-us" className="text-center py-20 px-6">
        <h1 className="text-5xl font-bold mb-4">About Us</h1>
        <p className="text-xl mb-8">
          Owner Dekhle is committed to ensuring the safety and peace of mind for
          vehicle owners and their families. We provide a simple yet effective
          solution to inform your loved ones in case of an emergency.
        </p>
      </section>

      {/* Mission Section */}
      <section id="mission" className="py-20 px-6 bg-[#00133e]">
        <h2 className="text-4xl font-bold text-center mb-12">Our Mission</h2>
        <p className="text-xl text-center max-w-3xl mx-auto">
          Our mission is to create a safer world for vehicle owners by providing
          a quick and easy way to notify family members in case of an emergency.
          We believe in leveraging technology to bring families closer and
          ensure their well-being.
        </p>
      </section>

      {/* Team Section */}
      <section id="team" className="py-20 px-6">
        <h2 className="text-4xl font-bold text-center mb-12">Meet Our Team</h2>
        <div className="grid md:grid-cols-3 gap-12">
          <div className="text-center">
            <img
              src="/path-to-your-image/team-member-1.jpg"
              alt="Team Member 1"
              className="mx-auto mb-4 rounded-full"
            />
            <h3 className="text-2xl font-semibold mb-2">Arun</h3>
            <p className="text-xl">Founder & CEO</p>
          </div>
          <div className="text-center">
            <img
              src="/path-to-your-image/team-member-2.jpg"
              alt="Team Member 2"
              className="mx-auto mb-4 rounded-full"
            />
            <h3 className="text-2xl font-semibold mb-2">Teja</h3>
            <p className="text-xl">Chief Technology Officer</p>
          </div>
          <div className="text-center">
            <img
              src="/path-to-your-image/team-member-3.jpg"
              alt="Team Member 3"
              className="mx-auto mb-4 rounded-full"
            />
            <h3 className="text-2xl font-semibold mb-2">Kunal</h3>
            <p className="text-xl">Chief Marketing Officer</p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section id="cta" className="text-center py-20 px-6 bg-[#00133e]">
        <h2 className="text-4xl font-bold mb-4">
          Ready to Protect Your Loved Ones?
        </h2>
        <p className="text-xl mb-8">
          Get started today with Owner Dekhle and ensure your family's safety.
        </p>
        <button className="bg-white text-[#00133e] font-semibold py-2 px-4 rounded">
          Get Started Now
        </button>
      </section>

      {/* Footer */}
      <footer className="bg-[#00133e] text-center py-6">
        <p>© 2024 Owner Dekhle. All rights reserved.</p>
        <div className="flex justify-center space-x-6 mt-4">
          <a href="#" className="hover:underline">
            Facebook
          </a>
          <a href="#" className="hover:underline">
            Twitter
          </a>
          <a href="#" className="hover:underline">
            Instagram
          </a>
        </div>
      </footer>
    </div>
  );
};

export default AboutUsPage;
