import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import {
  MdGroup,
  MdMessage,
  MdPeople,
  MdPeopleOutline,
  MdPerson,
  MdPhone,
  MdSupervisorAccount,
  MdOutlineNaturePeople,
} from "react-icons/md"; // Import the phone icon
import { GiThreeFriends } from "react-icons/gi";
import { FaPeoplePulling, FaPeopleRoof, FaPeopleLine } from "react-icons/fa6";

import API from "../api";

function InfoPage() {
  const { QRID } = useParams();
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    API.get(`/api/QR/get-qr-info/${QRID}`)
      .then((response) => {
        if (response.data.success) {
          setVehicleInfo(response.data.vehicleInfo);
        } else {
          setError("No vehicle information found.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [QRID]);

  if (loading) return <div className="text-center text-lg">Loading...</div>;
  if (error)
    return (
      <div className="text-center text-lg text-red-500">Error: {error}</div>
    );

  return (
    <div className=" bg-[#00133e] min-h-screen">
      <div className="bg-[#00133e] text-white font-sans">
        {/* Header */}
        <div className="text-3xl font-bold flex justify-center flex-1">
          <img
            src="	https://ownerdekhle.com/logo.png"
            className="h-100 w-100 items-center"
          ></img>
        </div>
      </div>
      <h1 className="text-3xl font-bold text-white">Vehicle Information</h1>

      {vehicleInfo && (
        <div className="space-y-3 mt-4">
          <p>
            <span className="font-semibold text-blue-300">Owner:</span>
            <span className="font-semibold text-white">
              {vehicleInfo.ownerName}
            </span>
          </p>
          <p>
            <span className="font-semibold text-green-300">Vehicle Type:</span>
            <span className="font-semibold text-white">
              {vehicleInfo.vehicleType}
            </span>
          </p>
          <p>
            <span className="font-semibold text-purple-300">
              Vehicle Number:
            </span>
            <span className="font-semibold text-white">
              {vehicleInfo.vehicleNumber}
            </span>
          </p>
          <p>
            <span className="font-semibold text-red-300">
              Registration State:
            </span>
            <span className="font-semibold text-white">
              {vehicleInfo.registrationState}
            </span>
          </p>
        </div>
      )}

      {vehicleInfo && (
        <div className="space-y-3 mt-4">
          <div className="grid grid-cols-2 gap-4">
            <button
              className={`${
                activeTab === "owner"
                  ? "bg-white text-[#004d81]"
                  : "bg-[#004d81] text-white"
              } px-4 py-2 rounded flex flex-col items-center text-3xl`}
              onClick={() => handleTabChange("owner")}
            >
              <MdOutlineNaturePeople className="mr-2 h-10 w-10 " />
              {vehicleInfo.driverName === "NA" ? "Owner" : "Driver"}
            </button>
            <button
              className={`${
                activeTab === "family"
                  ? "bg-white text-[#004d81]"
                  : "bg-[#004d81] text-white"
              } px-4 py-2 rounded flex flex-col items-center text-3xl`}
              onClick={() => handleTabChange("family")}
            >
              <FaPeopleRoof className="mr-2 h-10 w-10" />
              Family
            </button>
            <button
              className={`${
                activeTab === "friend"
                  ? "bg-white text-[#004d81]"
                  : "bg-[#004d81] text-white"
              } px-4 py-2 rounded flex flex-col items-center text-3xl`}
              onClick={() => handleTabChange("friend")}
            >
              <GiThreeFriends className="mr-2 h-10 w-10" />
              Friend
            </button>
            <button
              className={`${
                activeTab === "others"
                  ? "bg-white text-[#004d81]"
                  : "bg-[#004d81] text-white"
              } px-4 py-2 rounded flex flex-col items-center text-3xl`}
              onClick={() => handleTabChange("others")}
            >
              <FaPeopleLine className="mr-2 h-10 w-10" />
              Others
            </button>
          </div>
          {activeTab === "owner" && (
            <ListInfo
              member={{
                name:
                  vehicleInfo.driverName === "NA"
                    ? vehicleInfo.ownerName
                    : vehicleInfo.driverName,
                phoneNumber: vehicleInfo.customerPhoneNumber,
              }}
              index={-1}
              heading={vehicleInfo.driverName === "NA" ? "Owner" : "Driver"}
            />
          )}

          {activeTab === "family" && (
            <div>
              {/* <h2 className="text-2xl font-bold mt-4 text-green-600 mb-2">
                Family Numbers
              </h2> */}
              <ul>
                {vehicleInfo.family.map((member, index) => (
                  <ListInfo member={member} index={index} heading="Family" />
                ))}
              </ul>
            </div>
          )}

          {activeTab === "friend" && (
            <div>
              {/* <h2 className="text-2xl font-bold mt-4 text-purple-600 ">
                Friend Numbers
              </h2> */}
              <ul>
                {vehicleInfo.friends.map((member, index) => (
                  <ListInfo member={member} index={index} heading="Friend" />
                ))}
              </ul>
            </div>
          )}

          {activeTab === "others" && (
            <div>
              {/* <h2 className="text-2xl font-bold mt-4 text-red-600">
                Other Numbers
              </h2> */}
              <ul>
                {vehicleInfo.others.map((member, index) => (
                  <ListInfo member={member} index={index} heading="Other" />
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const ListInfo = ({ member, index, heading }) => {
  return (
    <li key={member._id}>
      <p className="flex items-center text-white">
        <span className="font-semibold mr-2 text-2xl ">
          {heading} {index > -1 && <span>{index + 1}</span>} -
        </span>
        <br />
        <span className="font-semibold text-xl underline">{member.name}</span>
      </p>
      <p className="flex items-center">
        <a
          href={`tel:${member.phoneNumber}`}
          className="text-blue-500 hover:text-blue-700 inline-flex items-center mb-4 bg-white w-full p-4 rounded-xl"
        >
          <MdPhone className="mr-2 text-xl h-10 w-10" />
          <span className="text-2xl">
            <span className="text-2xl">Call Now</span>
          </span>
        </a>
      </p>

      <p className="flex items-center">
        <a
          href={`https://wa.me/+91${member.phoneNumber}?text=Hello, I'm contacting you regarding your vehicle.`}
          className="text-green-500 hover:text-green-700 inline-flex items-center bg-white w-full p-4 rounded-xl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://pluspng.com/img-png/whatsapp-png-whatsapp-transparent-png-image-1012.png"
            alt="WhatsApp"
            className="mr-2 w-10 h-10"
          />
          <span className="text-2xl">Send WhatsApp</span>
        </a>
      </p>
      <div className="border-l border-gray-300 mx-4 h-full"></div>

      {/* {index !== vehicleInfo.friends.length - 1 && (
        <div className="border-l border-gray-300 mx-4 h-full"></div>
      )} */}
    </li>
  );
};

export default InfoPage;
