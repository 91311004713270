import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div className="min-h-screen bg-[#00133e] text-white font-sans">
      {/* Header */}
      <header className="flex flex-wrap justify-between items-center p-6">
        <div className="text-3xl font-bold">Owner Dekhle</div>
        <nav className="w-full mt-4 md:mt-0 md:w-auto">
          <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6">
            <li>
              <a href="/" className="hover:underline">
                Home
              </a>
            </li>
            <li>
              <a href="/download" className="hover:underline">
                Download
              </a>
            </li>
            <li>
              <a href="/about-us" className="hover:underline">
                About Us
              </a>
            </li>
            <li>
              <a href="/privacy-policy" className="hover:underline">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="#how-it-works" className="hover:underline">
                How It Works
              </a>
            </li>
            <li>
              <a href="#testimonials" className="hover:underline">
                Testimonials
              </a>
            </li>
            <li>
              <a href="#contact" className="hover:underline">
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="container mx-auto p-6">
        <h1 className="text-4xl font-bold mb-4 text-center">Privacy Policy</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
          <p className="text-lg">
            Owner Dekhle ("we," "our," or "us") is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, disclose,
            and safeguard your information when you use our Android application
            (the "App"). Please read this Privacy Policy carefully. If you do
            not agree with the terms of this Privacy Policy, please do not
            access the App.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">
            Information We Collect
          </h2>
          <p className="text-lg">
            We may collect information about you in a variety of ways. The
            information we may collect via the App includes:
          </p>
          <ul className="list-disc list-inside">
            <li className="mt-2">
              <span className="font-bold">Personal Data</span>: Information
              related to the vehicle and its owner, including name, contact
              information, and emergency contact details.
            </li>
            <li className="mt-2">
              <span className="font-bold">Family Details</span>: Information
              about family members for emergency contact purposes.
            </li>
            <li className="mt-2">
              <span className="font-bold">Agents</span>: Basic information for
              agents who assist in creating scanners, such as name and contact
              details. Note that we do not share agents' data.
            </li>
            <li className="mt-2">
              <span className="font-bold">Device Information</span>: We may
              collect information about your device, including the type of
              device, operating system, and unique device identifiers.
            </li>
            <li className="mt-2">
              <span className="font-bold">Usage Data</span>: Information about
              how you use the App, including access times, pages viewed, and
              other similar data.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">
            Use of Your Information
          </h2>
          <p className="text-lg">
            We use the information we collect for various purposes, including:
          </p>
          <ul className="list-disc list-inside">
            <li className="mt-2">To provide, operate, and maintain our App.</li>
            <li className="mt-2">
              To improve, personalize, and expand our App.
            </li>
            <li className="mt-2">
              To understand and analyze how you use our App.
            </li>
            <li className="mt-2">
              To develop new products, services, features, and functionality.
            </li>
            <li className="mt-2">
              To communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the App, and for
              marketing and promotional purposes.
            </li>
            <li className="mt-2">
              To process your transactions and manage your orders.
            </li>
            <li className="mt-2">To find and prevent fraud.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">
            Sharing Your Information
          </h2>
          <p className="text-lg">
            We may share information we have collected about you in certain
            situations. Your information may be disclosed as follows:
          </p>
          <ul className="list-disc list-inside">
            <li className="mt-2">
              <span className="font-bold">By Law or to Protect Rights</span>: If
              we believe the release of information about you is necessary to
              respond to legal process, to investigate or remedy potential
              violations of our policies, or to protect the rights, property,
              and safety of others, we may share your information as permitted
              or required by any applicable law, rule, or regulation.
            </li>
            <li className="mt-2">
              <span className="font-bold">Business Transfers</span>: We may
              share or transfer your information in connection with, or during
              negotiations of, any merger, sale of company assets, financing, or
              acquisition of all or a portion of our business to another
              company.
            </li>
            <li className="mt-2">
              <span className="font-bold">Third-Party Service Providers</span>:
              We may share your information with third parties that perform
              services for us or on our behalf, including payment processing,
              data analysis, email delivery, hosting services, customer service,
              and marketing assistance.
            </li>
            <li className="mt-2">
              <span className="font-bold">Affiliates</span>: We may share your
              information with our affiliates, in which case we will require
              those affiliates to honor this Privacy Policy. Affiliates include
              our parent company and any subsidiaries, joint venture partners,
              or other companies that we control or that are under common
              control with us.
            </li>
            <li className="mt-2">
              <span className="font-bold">With Your Consent</span>: We may
              disclose your personal information for any other purpose with your
              consent.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">
            Security of Your Information
          </h2>
          <p className="text-lg">
            We use administrative, technical, and physical security measures to
            help protect your personal information. While we have taken
            reasonable steps to secure the personal information you provide to
            us, please be aware that despite our efforts, no security measures
            are perfect or impenetrable, and no method of data transmission can
            be guaranteed against any interception or other type of misuse.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">Policy for Children</h2>
          <p className="text-lg">
            We do not knowingly solicit information from or market to children
            under the age of 13. If we learn that we have collected personal
            information from a child under age 13 without verification of
            parental consent, we will delete that information as quickly as
            possible. If you believe we might have any information from or about
            a child under 13, please contact us at support@ownerdekhle.com.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">
            Changes to This Privacy Policy
          </h2>
          <p className="text-lg">
            We may update this Privacy Policy from time to time in order to
            reflect, for example, changes to our practices or for other
            operational, legal, or regulatory reasons. We will notify you of any
            changes by posting the new Privacy Policy on this page. You are
            advised to review this Privacy Policy periodically for any changes.
            Changes to this Privacy Policy are effective when they are posted on
            this page.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
          <p className="text-lg">
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
          </p>
          <p className="text-lg">
            Email: ownerdekhle@gmail.com
            <br />
            Phone: +91-7056070407
          </p>
        </section>
      </div>

      {/* Footer */}
      <footer className="bg-[#00133e] text-center py-6">
        <p>© 2024 Owner Dekhle. All rights reserved.</p>
        <div className="flex justify-center space-x-6 mt-4">
          <a href="#" className="hover:underline">
            Facebook
          </a>
          <a href="#" className="hover:underline">
            Twitter
          </a>
          <a href="#" className="hover:underline">
            Instagram
          </a>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicyPage;
