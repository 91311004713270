import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-[#021135] text-white py-10 px-6">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {/* Company Info */}
        <div>
          <h3 className="text-2xl font-bold mb-4">Owner Dekhle</h3>
          <p>
            Owner Dekhle is committed to ensuring the safety and peace of mind
            for vehicle owners and their families. We provide a simple yet
            effective solution to inform your loved ones in case of an
            emergency.
          </p>
        </div>

        {/* Quick Links */}
        <div>
          <h4 className="text-xl font-semibold mb-4">Quick Links</h4>
          <ul>
            <li className="mb-2">
              <a href="/" className="hover:underline">
                Home
              </a>
            </li>
            <li className="mb-2">
              <a href="/about-us" className="hover:underline">
                About Us
              </a>
            </li>
            <li className="mb-2">
              <a href="/download" className="hover:underline">
                Download
              </a>
            </li>
            <li className="mb-2">
              <a href="#how-it-works" className="hover:underline">
                How It Works
              </a>
            </li>
            <li className="mb-2">
              <a href="#testimonials" className="hover:underline">
                Testimonials
              </a>
            </li>
            <li className="mb-2">
              <a href="#contact-us" className="hover:underline">
                Contact Us
              </a>
            </li>
          </ul>
        </div>

        {/* Contact Information */}
        <div>
          <h4 className="text-xl font-semibold mb-4">Contact Us</h4>
          <p className="mb-4">Email: ownerdekhle@gmail.com</p>
          <p className="mb-4">Phone: +91-7056070407</p>
          <a
            href="https://wa.me/+917056070407"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
          >
            <FaWhatsapp className="mr-2" /> Contact Us on WhatsApp
          </a>
        </div>

        {/* Social Media Links */}
        <div>
          <h4 className="text-xl font-semibold mb-4">Follow Us</h4>
          <div className="flex space-x-4">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-500"
            >
              <FaFacebook size={24} />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400"
            >
              <FaTwitter size={24} />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-pink-500"
            >
              <FaInstagram size={24} />
            </a>
          </div>
        </div>
      </div>
      <div className="text-center mt-8">
        <p>© 2024 Owner Dekhle. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
