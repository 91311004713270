import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import VehicleDetailsForm from "../Container/VehicleDetailsForm";
import API from "../api";

function FormPage() {
  const { tempId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rate, setRate] = useState({
    BasePrice: 0,
    price: 0,
    discount: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    API.get(`/api/QR/temp-id-info/${tempId}`)
      .then((response) => {
        if (response.data.success) {
          setData(response.data.data);
          const vehicleType = response.data.data.vehicleType;
          let calculatedRate;
          switch (vehicleType) {
            case "TWO_WHEELER":
              calculatedRate = {
                BasePrice: 200,
                price: 200,
                discount: 0,
              };
              break;
            case "E_RICKSHAW":
              calculatedRate = {
                BasePrice: 200,
                price: 200,
                discount: 0,
              };
              break;
            case "THREE_WHEELER":
              calculatedRate = {
                BasePrice: 200,
                price: 200,
                discount: 0,
              };
              break;
            case "FOUR_WHEELER":
              calculatedRate = {
                BasePrice: 300,
                price: 300,
                discount: 0,
              };
              break;
            case "HEAVY_VEHICLE":
              calculatedRate = {
                BasePrice: 500,
                price: 500,
                discount: 0,
              };
              break;
            default:
              calculatedRate = {
                BasePrice: 0,
                price: 0,
                discount: 0,
              };
          }
          setRate(calculatedRate);
        } else {
          setError("No data found for this Temp ID.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError("Error fetching data: " + err.message);
        setLoading(false);
      });
  }, [tempId]); // Refetch when tempId changes

  useEffect(() => {
    const handleBackButton = (event) => {
      if (isModalOpen) {
        closeModal();
        event.preventDefault();
      }
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isModalOpen]);

  const handleCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const openModal = () => {
    setIsModalOpen(true);
    window.history.pushState(null, null, window.location.href);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate(-1);
  };

  if (loading) return <div className="text-white">Loading...</div>;
  if (error) return <div className="text-red-500">Error: {error}</div>;
  if (!data) return <div className="text-white">No data available.</div>;

  return (
    <div className="p-6 bg-[#00133e] min-h-screen text-white text-lg">
      <h1 className="text-2xl font-bold mb-6">
        Info Page for Temp ID: <span className="text-xl">{tempId}</span>
      </h1>
      <div className="mt-4 space-y-2">
        <div className="flex text-xl text-[#004d81] font-bold justify-between bg-white p-2 rounded-md">
          <p>{data.vehicleType}</p>
          <div class="text-lg">
            {/* {rate.discount && (
              <span class="line-through text-gray-500">₹{rate.BasePrice}</span>
            )} */}
            <span class="font-bold">₹{rate.price}</span>
            {/* {rate.discount && (
              <span class="text-sm text-red-500">
                ({rate.discount}% Discount)
              </span>
            )} */}
          </div>
        </div>

        <p>
          <span className="font-bold">Customer Phone Number: </span>
          {data.customerPhoneNumber}
        </p>
        <p>
          <span className="font-bold">Status: </span>
          {data.status}
        </p>
        <p>
          <span className="font-bold">QRID 1: </span>
          {data.QRID_1}
        </p>
        <p>
          <span className="font-bold">QRID 2: </span>
          {data.QRID_2}
        </p>
        <p>
          <span className="font-bold"> QRID 3: </span>
          {data.QRID_3}
        </p>
        <p>
          <span className="font-bold"> Created At: </span>
          {new Date(data.createdAt).toLocaleString()}
        </p>
        <p>
          <span className="font-bold"> Updated At: </span>
          {new Date(data.updatedAt).toLocaleString()}
        </p>
      </div>

      {/* <div className="mt-4">
        <input
          type="checkbox"
          id="terms"
          checked={termsAccepted}
          onChange={handleCheckboxChange}
          className="mr-2"
        />
        <label htmlFor="terms">Accept Terms and Conditions</label>
        <button
          type="button"
          onClick={openModal}
          className="ml-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Show Terms and Conditions
        </button>
      </div> */}

      {isModalOpen && (
        <div className="fixed inset-0 m-4 bg-white bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full max-h-full overflow-y-auto relative text-black">
            <h2 className="text-xl font-bold mb-4">Terms and Conditions</h2>
            <ol className="list-disc pl-5 space-y-2">
              <li>
                <strong>Consent to Share Information:</strong> By using the
                Owner Dekhle service, you grant the company the right to share
                your contact number and other relevant information through the
                provided scanners.
              </li>
              <li>
                <strong>Accuracy of Information:</strong> The company is not
                responsible for any incorrect or false information provided by
                the users. It is the user’s responsibility to ensure the
                accuracy of the details submitted.
              </li>
              <li>
                <strong>Service Nature:</strong> This service is provided solely
                for user assistance and convenience. It does not serve as proof
                of ownership of any vehicle.
              </li>
              <li>
                <strong>Information Sharing:</strong> By accepting these terms
                and conditions, you give the company the right to share your
                contact number and other information as obtained through the
                scanners.
              </li>
              <li>
                <strong>Revocation of Service:</strong> The company reserves the
                right to revoke access to the scanners at any time without prior
                notice.
              </li>
              <li>
                <strong>Subscription Fees:</strong> After one year of using the
                scanner, the company reserves the right to charge a subscription
                fee for continued use of the service.
              </li>
              <li>
                <strong>Data Privacy:</strong> The company will handle all
                personal data in accordance with applicable data protection
                laws. However, by using the service, you acknowledge and accept
                that your contact information will be shared as described.
              </li>
              <li>
                <strong>User Responsibility:</strong> Users are responsible for
                the security and confidentiality of their access credentials to
                the service.
              </li>
              <li>
                <strong>Limitation of Liability:</strong> The company shall not
                be liable for any direct, indirect, incidental, or consequential
                damages arising from the use or inability to use the service.
              </li>
              <li>
                <strong>Modification of Terms:</strong> The company reserves the
                right to modify these terms and conditions at any time. Users
                will be notified of any significant changes, and continued use
                of the service constitutes acceptance of the new terms.
              </li>
              <li>
                <strong>Termination of Service:</strong> The company reserves
                the right to terminate the service for any user who violates
                these terms and conditions.
              </li>
              <li>
                <strong>Governing Law:</strong> These terms and conditions are
                governed by and construed in accordance with the laws of the
                jurisdiction in which the company operates.
              </li>
              <li>
                <strong>User Agreement:</strong> By using the Owner Dekhle
                service, you agree to abide by these terms and conditions. If
                you do not agree, please do not use the service.
              </li>
            </ol>
            <button
              onClick={closeModal}
              className="mt-4 p-2 bg-[#00133e] text-white rounded hover:bg-red-700 sticky bottom-4 w-full"
            >
              Close
            </button>
          </div>
        </div>
      )}

      <VehicleDetailsForm
        tempId={tempId}
        termsAccepted={termsAccepted}
        handleCheckboxChange={handleCheckboxChange}
        openModal={openModal}
      />
    </div>
  );
}

export default FormPage;
