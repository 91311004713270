// src/api.js
import axios from "axios";

const API = axios.create({
  // baseURL: "http://localhost:5000",
  baseURL: "https://owner-dekhle-backend-agent-c18ebeb001ca.herokuapp.com",
  timeout: 10000, // you can set this according to your needs
  headers: {
    "Content-Type": "application/json",
    // Add other headers here
  },
});

export default API;
