import React from "react";

const DeletePolicy = () => {
  return (
    <div className="min-h-screen bg-[#00133e] text-white font-sans">
      {/* Header */}
      <header className="flex flex-wrap justify-between items-center p-6">
        <div className="text-3xl font-bold">Owner Dekhle</div>
        <nav className="w-full mt-4 md:mt-0 md:w-auto">
          <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6">
            <li>
              <a href="/" className="hover:underline">
                Home
              </a>
            </li>
            <li>
              <a href="/download" className="hover:underline">
                Download
              </a>
            </li>
            <li>
              <a href="/about-us" className="hover:underline">
                About Us
              </a>
            </li>
            <li>
              <a href="/privacy-policy" className="hover:underline">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="#how-it-works" className="hover:underline">
                How It Works
              </a>
            </li>
            <li>
              <a href="#testimonials" className="hover:underline">
                Testimonials
              </a>
            </li>
            <li>
              <a href="#contact" className="hover:underline">
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="container mx-auto p-6">
        <h1 className="text-4xl font-bold mb-4 text-center">Delete Policy</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
          <p className="text-lg">
            Owner Dekhle ("we," "our," or "us") is committed to respecting your
            privacy and ensuring that you have control over your personal
            information. This Delete Policy outlines the steps users should take
            to request the deletion of their account, the types of data that are
            deleted or retained, and any additional retention periods.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">
            How to Request Account Deletion
          </h2>
          <p className="text-lg">
            If you wish to delete your Owner Dekhle account, please follow these
            steps:
          </p>
          <ol className="list-decimal list-inside ml-4">
            <li className="mt-2">
              Send an email to ownerdekhle@gmail.com from the email address
              associated with your Owner Dekhle account.
            </li>
            <li className="mt-2">
              Use the subject line "Account Deletion Request."
            </li>
            <li className="mt-2">
              Include your account username and any other relevant information
              that may help us identify your account.
            </li>
          </ol>
          <p className="text-lg mt-4">
            Once we receive your request, we will verify your identity and
            process your account deletion within 30 days. You will receive a
            confirmation email once the deletion process is complete.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">Types of Data Deleted</h2>
          <p className="text-lg">
            Upon successful account deletion, the following types of data will
            be permanently deleted from our servers:
          </p>
          <ul className="list-disc list-inside ml-4">
            <li className="mt-2">
              Personal Information: Name, contact details, emergency contacts,
              and any other personal information provided.
            </li>
            <li className="mt-2">
              Account Information: Account credentials, usage history, and
              preferences.
            </li>
            <li className="mt-2">
              Device Information: Data related to your device, such as device
              type, operating system, and unique device identifiers.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">
            Types of Data Retained
          </h2>
          <p className="text-lg">
            In certain circumstances, we may retain some data for a limited
            period even after account deletion:
          </p>
          <ul className="list-disc list-inside ml-4">
            <li className="mt-2">
              Transaction Records: Data related to transactions may be retained
              for up to 7 years to comply with legal and regulatory
              requirements.
            </li>
            <li className="mt-2">
              Customer Support Correspondence: Any communications with our
              customer support team may be retained for up to 2 years for
              quality assurance and training purposes.
            </li>
            <li className="mt-2">
              Anonymized Data: Data that has been anonymized and cannot be
              traced back to an individual user may be retained indefinitely for
              analytical and statistical purposes.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">
            Additional Retention Period
          </h2>
          <p className="text-lg">
            While most of your data will be deleted upon account deletion,
            certain types of data may be retained for a longer period due to
            legal obligations or legitimate business purposes. These include:
          </p>
          <ul className="list-disc list-inside ml-4">
            <li className="mt-2">
              Legal Obligations: Data required to comply with legal obligations
              or court orders.
            </li>
            <li className="mt-2">
              Fraud Prevention: Data necessary to prevent fraud or abuse of our
              services.
            </li>
            <li className="font-extrabold text-2xl">
              Once the Account is deleted, user can't use same Mobile Number to
              create new account.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
          <p className="text-lg">
            If you have any questions or concerns regarding this Delete Policy
            or the deletion of your data, please contact us at:
          </p>
          <p className="text-lg">
            Email: ownerdekhle@gmail.com
            <br />
            Phone: +91-7056070407
          </p>
        </section>

        <p className="text-lg">
          By using our app, you agree to this Delete Policy. We reserve the
          right to make changes to this policy at any time. If we make material
          changes, we will notify you by email or through a notice on our app.
        </p>
      </div>
    </div>
  );
};

export default DeletePolicy;
