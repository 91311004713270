import React from "react";
import { servicesData } from "../assets/servicesData";
import { Link } from "react-router-dom";

const iconsLocal = {
  threeWheelerIcon: require(`../assets/servicesIcons/tuk-tuk.png`),
  twoWheelerIcon: require(`../assets/servicesIcons/motorcycle.png`),
  fourWheelerIcon: require(`../assets/servicesIcons/car.png`),
  heavyVehicleIcon: require(`../assets/servicesIcons/delivery-truck.png`),
  keyChainIcon: require(`../assets/servicesIcons/house-key.png`),
  kidsLocketIcon: require(`../assets/servicesIcons/pendant.png`),
  agentsIcon: require(`../assets/servicesIcons/partners.png`),
  walletIcon: require(`../assets/servicesIcons/wallet.png`),
  babyIcon: require(`../assets/servicesIcons/baby.png`),
  babyIcon2: require(`../assets/servicesIcons/bracelet.png`),
  doorBellIcon: require(`../assets/servicesIcons/door-bell.png`),
  luggageTagIcon: require(`../assets/servicesIcons/traveler.png`),
  petIcon: require(`../assets/servicesIcons/collar.png`),
  mobileIcon: require(`../assets/servicesIcons/communication.png`),
  laptopIcon: require(`../assets/servicesIcons/worker.png`),
  veerSammanIcon: require(`../assets/servicesIcons/military-man.png`),
  productsIcon: require(`../assets/servicesIcons/best-product.png`),
  buildingIcon: require(`../assets/servicesIcons/hawa-mahal.png`),
  customerIcon: require(`../assets/servicesIcons/tempId.png`),
  eRichshawIcon: require(`../assets/servicesIcons/eRickshaw1.png`),
  handocap: require(`../assets/servicesIcons/handicap.png`),
  walletTagIcon: require(`../assets/servicesIcons/wallet-2.png`),
  menuIcon2: require(`../assets/servicesIcons/menu-1.png`),
  loveMemoriesIcon: require(`../assets/servicesIcons/tattoo.png`),
  handicapIcon: require(`../assets/servicesIcons/handicap.png`),
  humanHelpIcon: require(`../assets/servicesIcons/humanHelp.png`),
  profileIcon: require(`../assets/servicesIcons/profile.png`),
};

// const services = [
//   {
//     name: "Two Wheeler Security",
//     description: "QR codes for easy tracking and protection of two-wheelers.",
//     icon: iconsLocal.twoWheelerIcon,
//   },
//   {
//     name: "Three Wheeler Security",
//     description: "Monitor and safeguard your three-wheelers with QR codes.",
//     icon: iconsLocal.threeWheelerIcon,
//   },
//   {
//     name: "Four Wheeler Security",
//     description: "QR-enabled solutions for car security and tracking.",
//     icon: iconsLocal.fourWheelerIcon,
//   },
//   {
//     name: "Heavy Vehicle Security",
//     description: "Efficient tracking for heavy vehicles using QR technology.",
//     icon: iconsLocal.heavyVehicleIcon,
//   },
//   {
//     name: "E-Rickshaw Security",
//     description: "QR codes for E-Rickshaw safety and monitoring.",
//     icon: iconsLocal.eRichshawIcon,
//   },
//   {
//     name: "Keychain Tracking",
//     description: "Never lose your keys again with QR-enabled tracking.",
//     icon: iconsLocal.keyChainIcon,
//   },
//   {
//     name: "Kids Locket",
//     description: "Ensure your child's safety with QR-enabled lockets.",
//     icon: iconsLocal.kidsLocketIcon,
//   },
//   {
//     name: "New Born Baby",
//     description: "QR tags for ensuring newborn safety in hospitals.",
//     icon: iconsLocal.babyIcon,
//   },
//   {
//     name: "Pet Tracking",
//     description: "Track your pets with QR codes for peace of mind.",
//     icon: iconsLocal.petIcon,
//   },
//   {
//     name: "Luggage Tagging",
//     description: "QR tags for easy tracking of your luggage.",
//     icon: iconsLocal.luggageTagIcon,
//   },
//   {
//     name: "Mobile Safety",
//     description: "Secure your mobile with QR codes.",
//     icon: iconsLocal.mobileIcon,
//   },
//   {
//     name: "Handicap",
//     description: "Secure your mobile with QR codes.",
//     icon: iconsLocal.handocap,
//   },
//   {
//     name: "Multi Purpose",
//     description: "QR stickers for securing laptops and devices.",
//     icon: iconsLocal.laptopIcon,
//   },
//   {
//     name: "Door Bell Monitoring",
//     description: "Enhance home security with QR-enabled doorbells.",
//     icon: iconsLocal.doorBellIcon,
//   },
//   {
//     name: "Veer Samman",
//     description: "QR-enabled services to honor and protect our heroes.",
//     icon: iconsLocal.veerSammanIcon,
//   },
//   {
//     name: "Building History",
//     description: "Explore building histories with QR codes.",
//     icon: iconsLocal.buildingIcon,
//   },
// ];

const services = servicesData;

const OurServices = () => {
  return (
    <section className="py-20 px-1 bg-[#00133e]" id="our-services">
      <div className="max-w-8xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8 text-white">
          Change Life, Safe Life
        </h2>
        <p className="text-lg mb-16 text-gray-300">
          We provide advanced QR code solutions for a wide range of services.
        </p>
        <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-8">
          {services.map((service, index) => (
            <Link
              to={`/service/${service.id}`}
              key={index}
              className="bg-white shadow-lg rounded-lg pt-2 p-1 md:p-6 hover:shadow-xl transition-shadow duration-300"
            >
              <img
                src={service.icon}
                alt={service.name}
                className="w-16 h-16 mx-auto mb-4"
              />
              <h3 className="text-md font-semibold  text-gray-800 text-sm">
                {service.name}
              </h3>
              <p className="text-gray-600 text-sm mt-2 hidden sm:block sm:text-base">
                {service.description}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurServices;
