import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { MdPhone } from "react-icons/md";
import API from "../api";

function KeyChainInfoPage() {
  const { QRID } = useParams();
  const [keyChainInfo, setKeyChainInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  console.log("hellonp");
  useEffect(() => {
    API.get(`/api/QR/get-key-chain-info/${QRID}`)
      .then((response) => {
        if (response.data.success) {
          setKeyChainInfo(response.data.keyChainInfo);
        } else {
          setError("No information found.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [QRID]);

  if (loading) return <div className="text-center text-lg">Loading...</div>;
  if (error)
    return (
      <div className="text-center text-lg text-red-500">Error: {error}</div>
    );

  return (
    <div className="p-4 bg-[#00133e] min-h-screen">
      <h1 className="text-3xl font-bold text-white">Key Chain Information</h1>

      {keyChainInfo && (
        <div className="space-y-3 mt-4">
          <p>
            <span className="font-semibold text-blue-300">Owner:</span>
            <span className="font-semibold text-white">
              {keyChainInfo.ownerName}
            </span>
          </p>
          <PhoneLink label="Phone 1" phoneNumber={keyChainInfo.phoneNumber1} />
          <PhoneLink label="Phone 2" phoneNumber={keyChainInfo.phoneNumber2} />
          <PhoneLink label="Phone 3" phoneNumber={keyChainInfo.phoneNumber3} />
        </div>
      )}
    </div>
  );
}

const PhoneLink = ({ label, phoneNumber }) => {
  if (!phoneNumber) return null; // Do not render anything if phoneNumber is not available
  return (
    <p>
      <span className="font-semibold text-green-300">{label}:</span>
      <a
        href={`tel:${phoneNumber}`}
        className="text-blue-500 hover:text-blue-700 inline-flex items-center mb-4 bg-white w-full p-4 rounded-xl"
      >
        <MdPhone className="mr-2 text-xl h-10 w-10" />
        <span className="text-2xl">{phoneNumber}</span>
      </a>
    </p>
  );
};

export default KeyChainInfoPage;
