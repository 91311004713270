import React from "react";

const ContactUsPage = () => {
  return (
    <div className="min-h-screen bg-[#00133e] text-white font-sans">
      {/* Contact Us Section */}
      {/* <section id="contact-us" className="text-center py-20 px-6">
        <h1 className="text-5xl font-bold mb-4">Contact Us</h1>
        <p className="text-xl mb-8">
          Have questions or need assistance? Get in touch with us, and we’ll
          help you out as soon as possible.
        </p>
      </section> */}
      <section id="support" className="py-20 px-6 bg-[#00133e] text-center">
        <h2 className="text-4xl font-bold mb-8">Need More Help?</h2>
        <p className="text-xl mb-4">
          You can also reach us directly through our support email or phone
          number.
        </p>
        <p className="text-xl">
          <strong>Support Email:</strong> ownerdekhle@gmail.com
        </p>
        <p className="text-xl">
          <strong>Phone Number:</strong> +91-7056070407
        </p>
      </section>
      {/* Contact Form Section */}
      <section id="contact-form" className="py-20 px-6 bg-[#00133e]">
        <h2 className="text-4xl font-bold text-center mb-12">Get in Touch</h2>
        <form className="max-w-3xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <input
              type="text"
              placeholder="Your Name"
              className="py-3 px-4 rounded bg-white text-[#00133e] outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="email"
              placeholder="Your Email"
              className="py-3 px-4 rounded bg-white text-[#00133e] outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-6">
            <input
              type="text"
              placeholder="Subject"
              className="w-full py-3 px-4 rounded bg-white text-[#00133e] outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-6">
            <textarea
              placeholder="Your Message"
              className="w-full py-3 px-4 rounded bg-white text-[#00133e] outline-none focus:ring-2 focus:ring-blue-500 h-40"
            />
          </div>
          <button className="bg-white text-[#00133e] font-semibold py-2 px-4 rounded">
            Send Message
          </button>
        </form>
      </section>

      {/* Call to Action Section */}
      <section id="cta" className="text-center py-20 px-6 bg-[#00133e]">
        <h2 className="text-4xl font-bold mb-4">
          Stay Connected with Owner Dekhle
        </h2>
        <p className="text-xl mb-8">
          Follow us on social media for the latest updates and news.
        </p>
        <div className="flex justify-center space-x-6 mt-4">
          <a href="#" className="hover:underline">
            <div className="flex flex-col justify-center items-center">
              <img src="socialIcon/facebook.png" height={50} width={50} />
            </div>
            Facebook
          </a>
          <a href="#" className="hover:underline">
            <div className="flex flex-col justify-center items-center">
              <img src="socialIcon/instagram.png" height={50} width={50} />
            </div>
            Instagram
          </a>
          <a href="#" className="hover:underline">
            <div className="flex flex-col justify-center items-center">
              <img src="socialIcon/twitter.png" height={50} width={50} />
            </div>
            Twitter
          </a>
          <a href="#" className="hover:underline">
            <div className="flex flex-col justify-center items-center">
              <img src="socialIcon/youtube.png" height={50} width={50} />
            </div>
            Youtube
          </a>
          <a href="#" className="hover:underline">
            <div className="flex flex-col justify-center items-center">
              <img src="socialIcon/whatsapp.png" height={50} width={50} />
            </div>
            Whatsapp
          </a>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-[#00133e] text-center py-6">
        <p>© 2024 Owner Dekhle. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default ContactUsPage;
