import React from "react";

const DownloadPage = () => {
  return (
    <div className="min-h-screen bg-[#00133e] text-white font-sans">
      {/* Header */}
      <header className="flex flex-wrap justify-between items-center p-6">
        <div className="text-3xl font-bold flex">
          <img src="logo.png" className="h-10 w-10 items-center"></img>Owner
          Dekhle
        </div>{" "}
        <nav className="w-full mt-4 md:mt-0 md:w-auto">
          <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6">
            {/* <li>
              <a href="#home" className="hover:underline">
                Home
              </a>
            </li>
            <li>
              <a href="#about" className="hover:underline">
                About Us
              </a>
            </li>
            <li>
              <a href="#how-it-works" className="hover:underline">
                How It Works
              </a>
            </li>
            <li>
              <a href="#testimonials" className="hover:underline">
                Testimonials
              </a>
            </li>
            <li>
              <a href="#contact" className="hover:underline">
                Contact
              </a>
            </li> */}
          </ul>
        </nav>
      </header>

      {/* Download Section */}
      <section id="download" className="text-center py-20 px-6">
        <h1 className="text-5xl font-bold mb-4">Download Owner Dekhle App</h1>
        <p className="text-xl mb-8">
          Get the Owner Dekhle app to ensure your loved ones are always informed
          and safe.
        </p>
        <a href="ownerdekhlebusiness.apk" download>
          <button className="bg-white text-[#00133e] font-semibold py-2 px-4 rounded">
            Download APK
          </button>
        </a>
      </section>

      {/* Call to Action Section */}
      <section id="cta" className="text-center py-20 px-6 bg-[#00133e]">
        <h2 className="text-4xl font-bold mb-4">
          Ready to Protect Your Loved Ones?
        </h2>
        <p className="text-xl mb-8">
          Get started today with Owner Dekhle and ensure your family's safety.
        </p>
        <button className="bg-white text-[#00133e] font-semibold py-2 px-4 rounded">
          Get Started Now
        </button>
      </section>

      {/* Footer */}
      <footer className="bg-[#00133e] text-center py-6">
        <p>© 2024 Owner Dekhle. All rights reserved.</p>
        <div className="flex justify-center space-x-6 mt-4">
          <a href="#" className="hover:underline">
            Facebook
          </a>
          <a href="#" className="hover:underline">
            Twitter
          </a>
          <a href="#" className="hover:underline">
            Instagram
          </a>
        </div>
      </footer>
    </div>
  );
};

export default DownloadPage;
