import React from "react";
import { useParams } from "react-router-dom";
import { servicesData } from "../assets/servicesData";

const ServiceDetailPage = () => {
  const { serviceId } = useParams();
  const service = servicesData.find((s) => s.id === serviceId);

  if (!service) {
    return <p>Service not found</p>;
  }

  return (
    <section className="py-20 px-6 bg-white text-gray-800">
      <div className="max-w-4xl mx-auto">
        {/* Service Header */}
        <div className="text-center mb-12">
          <img
            src={service.icon}
            alt={service.name}
            className="w-20 h-20 mx-auto mb-6"
          />
          <h1 className="text-4xl font-bold mb-4">{service.name}</h1>
          <p className="text-lg">{service.description}</p>
        </div>

        {/* Real Photos Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Real-Life Examples</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {service.realPhotos.map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt={`${service.name} example ${index + 1}`}
                className="w-full h-auto rounded-lg shadow-md"
              />
            ))}
          </div>
        </div>

        {/* Service Rate Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Service Rate</h2>
          <p className="text-lg">
            The rate for {service.name} is{" "}
            <strong>{service.serviceRate}</strong>.
          </p>
        </div>

        {/* QR Code Scanner Information */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">About QR Code Scanner</h2>
          <p className="text-lg">
            Our QR code scanner provides a seamless experience, ensuring that
            you can easily access the information and services you need. It is
            designed to work efficiently and quickly, whether you are tracking a
            vehicle, ensuring the safety of your loved ones, or accessing
            important data.
          </p>
        </div>

        {/* Agent Information Section */}
        <div className="bg-gray-100 p-6 rounded-lg">
          <h2 className="text-2xl font-semibold mb-4">Become an Agent</h2>
          <p className="text-lg">
            All our services are created by Owner Dekhle Agents. You too can
            become an agent and earn money by making scanners for others. Join
            our network of agents and start your journey towards financial
            independence while helping others secure their valuable assets.
          </p>
          <button className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700">
            Become an Agent
          </button>
        </div>
      </div>
    </section>
  );
};

export default ServiceDetailPage;
